import { render, staticRenderFns } from "./PickerTree.vue?vue&type=template&id=08272204&scoped=true"
import script from "./PickerTree.vue?vue&type=script&lang=js"
export * from "./PickerTree.vue?vue&type=script&lang=js"
import style0 from "./PickerTree.vue?vue&type=style&index=0&id=08272204&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08272204",
  null
  
)

export default component.exports